export default [
  {
    text: "Загрузка зачётной книжки",
    value: true,
  },
  {
    text: "Загрузка справки с места учёбы",
    value: false,
  },
];

<template>
  <div class="performance">
    <Title label="Документы" border />

    <div class="performance__body">
      <div class="documents__desc">
        <p>Копии прикрепляемых документов должны быть формата А4</p>
        <p>
          Все документы необходимо загружать с персонального компьютера или
          ноутбука во избежание технических неполадок
        </p>
      </div>
      <DocsComponent type="education" />
    </div>
    <div class="performance--docs-upload-info">
      *файлы с разрешением png, jpg размером не более 2 Мб
    </div>
    <div class="performance__btn">
      <Button
        @click="saveInfo"
        :loading="isBtnLoading"
        :disabled="isBtnDisabled"
      >
        Сохранить изменения
      </Button>
    </div>
  </div>
</template>

<script>
// import DocsUpload from "@/components/Blocks/DocsUpload";
import Button from "@/components/Blocks/Button";
import Title from "@/components/Blocks/Title";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { mapGetters, mapState } from "vuex";
import educationsRadio from "@/enums/educationsRadio";
import documentsLabels from "@/enums/documentsLabels";
// import PDFInputFile from "@/components/Blocks/PDFInputFile";
import DocsComponent from "@/components/Blocks/DocsComponent.vue";
export default {
  name: "Performance",
  components: {
    DocsComponent,
    // PDFInputFile,
    Title,
    Button,
    // DocsUpload
  },
  data() {
    return {
      educationsRadio,
      documentsLabels,
      isBtnLoading: false,
      isBtnDisabled: true,
      formData: {
        document_student_billet: "",
        document_student_record_book_main_page: "",
        document_student_record_book_session_pages_one: "",
        document_student_record_book_session_pages_two: "",
        document_student_record_book_session_pages_three: "",
        document_student_record_book_session_pages_four: "",
        document_student_record_book_session_pages_five: "",
        document_reference: "",
        document_higher_education: "",
        document_social_need: "",
        document_certificate_absence_academic_debt: "",
        document_certificate_guardianship_adoption: "",
        document_certificate_family_composition: "",
        document_reference_scholarship_amount: "",
        document_certificate_personal_income_student_last_3_months: "",
        document_consent_parents: "",
        document_reference_income_parent_one: "",
        document_reference_income_parent_two: "",
      },
    };
  },
  watch: {
    user() {
      this.loadInfo();
    },
    docs: {
      handler: function () {
        Object.keys(this.formData).forEach((item) => {
          return (this.formData[item] = this.docs[item]);
        });
        this.isBtnDisabled = false;
      },
      deep: true,
    },
  },
  computed: {
    educationDocsList() {
      let buffer = JSON.parse(JSON.stringify(this.allTypeDocs));
      buffer = buffer.filter((doc) => {
        return (
          !this.russianPassportDocs.includes(doc) &&
          !this.foreignPassportDocs.includes(doc) &&
          doc !== "document_expert_order_appointment"
        );
      });
      return buffer;
    },
    currentDocsListWithoutDiplom() {
      return this.educationDocsList?.filter((item) => {
        return (
          item !== "document_higher_education" &&
          item !== "document_social_need" &&
          item !== "document_consent_parents" &&
          item !==
            "document_certificate_personal_income_student_last_3_months" &&
          item !== "document_reference_income_parent_one" &&
          item !== "document_reference_income_parent_two"
        );
      });
    },
    ...mapState("user", ["user"]),
    ...mapState(["docs", "storage"]),
    ...mapGetters([
      "russianPassportDocs",
      "foreignPassportDocs",
      "allTypeDocs",
      "socialDocs",
    ]),
  },
  methods: {
    async saveInfo() {
      let payloadObject = {};
      Object.keys(this.formData).forEach((item) => {
        if (
          (this.user?.education?.document_files[item]?.full ||
            this.user?.social_documents?.document_files[item]?.full) &&
          this.formData[item].full
        ) {
          this.formData[item] = "";
        } else {
          if (this.user.info_validation_documents.data.length > 0) {
            let infoDocument = this.user.info_validation_documents.data.find(
              (i) => {
                return i.key_document === item;
              }
            );
            if (infoDocument) {
              infoDocument.status = 0;
            }
          }
        }
        if (this.formData[item]) {
          return (payloadObject[item] = this.formData[item]);
        }
      });

      this.isBtnLoading = true;

      await createRequest(requestConfigs.POSTSaveEducation, {
        jsonPayload: payloadObject,
      })
        .then((response) => {
          this.$store.commit("pushToTray", {
            text: "Данные успешно сохранены",
            type: "success",
          });
          Object.keys(response.data).forEach((item) => {
            if (this.socialDocs.includes(item)) {
              this.user.social_documents.document_files[item] =
                response.data[item];
            } else
              this.user.education.document_files[item] = response.data[item];
          });
          Object.keys(this.formData).forEach((item) => {
            if (this.socialDocs.includes(item)) {
              this.formData[item] =
                this.user?.social_documents?.document_files[item];
            } else
              this.formData[item] = this.user?.education?.document_files[item];
            this.docs[item] = "";
          });
          this.isBtnDisabled = true;
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
    },
    loadInfo() {
      if (this.user?.education && this.user?.social_documents) {
        Object.keys(this.formData).forEach((item) => {
          if (
            this.user.education.document_files[item] &&
            this.user.education.document_files[item].full
          ) {
            return (this.formData[item] =
              this.user.education.document_files[item]);
          } else if (
            this.user.social_documents.document_files[item] &&
            this.user.social_documents.document_files[item].full
          ) {
            return (this.formData[item] =
              this.user.social_documents?.document_files[item]);
          } else return (this.formData[item] = this.docs[item]);
        });
      }
    },
  },
  mounted() {
    this.loadInfo();
    for (const doc in this.formData) {
      this.$store.state.docs[doc] = "";
    }
  },
};
</script>

<style lang="scss">
.performance {
  .documents__desc {
    font-size: 1.6rem;
  }
  &__body {
    margin: 45px 0 24px 0;
    @include adaptive(tablet-big) {
      margin-top: 30px;
    }
  }
  &__btn {
  }
  &--docs-upload-info {
    font-size: 1rem;
    line-height: 1.2rem;
    color: var(--color--disabled);
    margin-bottom: 15px;
    display: none;
    @include adaptive(tablet-small) {
      display: block;
    }
  }
}
</style>
